
<template>
  <div>
    <div>
      <ul class="qc-top">
        <li>
          <ave-search v-model="searchValue" placeholder="搜索任务单ID" style="width:240px;" />
        </li>
        <li>
          <span>创建时间:</span>
          <ave-datepicker v-model="beginDate" format="yyyy-MM-dd hh:mm" :showtime="false" />
          <span>-</span>
          <ave-datepicker v-model="endDate" format="yyyy-MM-dd hh:mm" :showtime="false" />
        </li>
        <li>
          <span>状态:</span>
          <ave-select v-model="chStatus" :options="searchStatus" style="width:160px" />
        </li>
        <li>
          <span>服务供应商:</span>
          <ave-select v-model="chSupplier" :options="searchSuppliers" style="width:160px" />
        </li>
        <li style="padding-top:4px;">
          <button class="order-resetbtn" @click="reset">
            重置
          </button>
          <button class="order-searchbtn" @click="initData">
            查询
          </button>
        </li>
      </ul>
    </div>
    <div style="clear:both;" />
    <div style="height:12px;" />
    <div>
      <table class="table">
        <thead>
          <tr class="thead-tr">
            <th width="14%">
              任务单ID
            </th>
            <th width="12%">
              <span>创建时间</span>
              <span
                class="caret-wrapper"
                @click="sortData(1)"
                :class="createTimeAscend ? 'ascending' : 'descending'"
              >
                <i class="sort-caret ascending" />
                <i class="sort-caret descending" />
              </span>
            </th>
            <th width="10%">
              翻译内容
            </th>
            <th width="7%">
              源语
            </th>
            <th>目标语</th>
            <th>开发者联系方式</th>
            <th width="8%">
              <span>费用</span>
              <span
                class="caret-wrapper"
                @click="sortData(2)"
                :class="costAscend ? 'ascending' : 'descending'"
              >
                <i class="sort-caret ascending" />
                <i class="sort-caret descending" />
              </span>
            </th>
            <th width="10%">
              <span>服务供应商</span>
            </th>
            <th width="6%">
              状态
            </th>
            <th width="8%">
              操作
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item,idx) in data">
            <tr :key="'tr2' + idx" class="tr-main">
              <td>{{ item.orderNo }}</td>
              <td>{{ item.createTime }}</td>
              <td>{{ item.applicationName }}</td>
              <td>{{ item.sourceLanguage }}</td>
              <td>{{ item.targetLanguage }}</td>
              <td>{{ item.devEmail }}</td>
              <td>
                <span>RMB</span>
                <span>{{ item.cost }}</span>
              </td>
              <td>{{ item.expectDeliveryTime }}</td>
              <td>{{ status[item.status] }}</td>
              <td>
                <span @click="showDetails(item)" class="orderlist-process">查询</span>
                <span
                  @click="downloadVisiable = true;"
                  v-if="item.status === 'pay'"
                  class="orderlist-process"
                >下载</span>

                <span
                  v-if="item.status === 'end'"
                  @click="openAppraiseDialog(item.taskId,item.supplierId)"
                  class="orderlist-process"
                >查看评价</span>
                <span
                  v-if="item.status === 'completed' ||
                    item.status === 'refresh'"
                  @click="qc.dialogVisible = true;"
                  class="orderlist-process"
                >发起确认</span>
              </td>
            </tr>
          </template>
          <tr v-if="data.length < 1" class="tr-main">
            <td colspan="10" class="orderlist-tr-nodata">
              未查询到相关数据
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="orderlist-bottom" v-if="data.length > 0">
      <span style="width:68%;display:inline-block;" />
      <ave-pager :pager="pager" @change="onPage($event)" :show-page-size="false" />
    </div>

    <div style="height:12px;border-top:1px solid #E2E2E2;" />

    <div class="toasts-wrapper">
      <ave-toasts :msgs="toasts" :life="3000" />
    </div>

    <!--质量确认弹窗-->
    <el-dialog :visible.sync="qc.dialogVisible" width="560px">
      <div>
        <div class="detail-dialog-div">
          <div>
            <span v-if="qualityConfirm !== 'yes'" class="blue">不修改说明</span>
            <span v-if="qualityConfirm === 'yes'" class="blue">修改说明</span>
          </div>
          <label class="ave-tip-input" style="width:100%;">
            <textarea type="text" style="height:72px;" v-model="qc.remarks" />
          </label>
        </div>
        <div class="detail-dialog-div">
          <span class="blue">附件（非必选）:</span>
          <span @click="chooseTM('qcAppendix')" class="af-upload" />
          <input
            type="file"
            id="qcAppendix"
            ref="qc_appendix"
            style="display:none;"
            @change="cosTranslated($event,'appendix_path')"
          >
          <span class="detail-dialog-span">{{ qc.appendixPath }}</span>
        </div>
        <div class="detail-dialog-div" v-if="qualityConfirm === 'yes'">
          <span class="blue">刷新交付件:</span>
          <span @click="chooseTM('qcFile')" class="af-upload" />
          <input
            type="file"
            id="qcFile"
            ref="qc_file"
            style="display:none;"
            @change="cosTranslated($event,'qc_file_path')"
          >
          <span class="detail-dialog-span">{{ qc.qcFilePath }}</span>
        </div>
        <div style="padding-top:24px;text-align:center;">
          <button class="order-nextstep" @click="submitQc">
            提交
          </button>
          <button class="order-empty" @click="qcCancel">
            取消
          </button>
        </div>
      </div>
    </el-dialog>

    <!--评价弹窗-->
    <el-dialog title="评价" :visible.sync="appraiseDialogVisible" width="480px">
      <div class="appraise-top">
        <ul>
          <li>
            <span>交付及时性:</span>
            <template v-for="idx of 5">
              <span
                class="supplier-mar-right"
                :class="idx <= appraise.delivery ? 'af-star' : 'af-unstar'"
                :key="idx"
              />
            </template>
          </li>
          <li>
            <span>质量满意度:</span>
            <template v-for="idx of 5">
              <span
                class="supplier-mar-right"
                :class="idx <= appraise.quality ? 'af-star' : 'af-unstar'"
                :key="idx"
              />
            </template>
          </li>
          <li>
            <span>服务满意度:</span>
            <template v-for="idx of 5">
              <span
                class="supplier-mar-right"
                :class="idx <= appraise.service ? 'af-star' : 'af-unstar'"
                :key="idx"
              />
            </template>
          </li>
        </ul>
        <div class="appraise-text">
          {{ appraise.remarks }}
        </div>
      </div>
    </el-dialog>

    <!--详情-->
    <sp-detail-dialog
      :detail-data="detailData"
      :dialog-visible="detailDialogVisiable"
      @changeVisiable="changeVisiable"
      @openUploadDialog="openUploadDialog"
    />

    <download-dialog
      :download-visible="downloadVisiable"
      :this-data="thisData"
      @cDownloadVisiable="cDownloadVisiable"
    />
  </div>
</template>

<script>
import { getCPTeamIdData } from '../../util/common.js';
import Cookie from 'js-cookie';
export default {
  name: 'BackstageOrderlist',
  data() {
    return {
      searchStatus: [
        { value: 'all', name: '全部' },
        { value: 'nopay', name: '待支付' },
        { value: 'pay', name: '已支付' },
        { value: 'translate', name: '处理中' },
        { value: 'completed', name: '已回稿' },
        { value: 'refresh', name: '已刷新' },
        { value: 'end', name: '已完成' }
      ],
      status: {
        init: '待确认',
        nopay: '待支付',
        pay: '待处理',
        translate: '处理中',
        appraise: '待评价',
        end: '已完成'
      },
      searchSuppliers: [
        { value: 'all', name: '全部' },
        { value: 'A', name: '供应商A' },
        { value: 'B', name: '供应商B' }
      ],
      chStatus: {},
      chSupplier: {},
      beginDate: new Date().format('yyyy-MM-dd hh:mm'),
      endDate: new Date().format('yyyy-MM-dd hh:mm'),
      searchValue: '',
      costAscend: true,
      createTimeAscend: false,
      data: [],
      toasts: [],
      detailDialogVisiable: false,
      detailData: {},
      downloadVisiable: false,
      dialogVisible: false,
      appraiseDialogVisible: false, // 显示评价弹窗
      pager: {
        total: 23,
        index: 0,
        pageSize: 10
      },
      supplier: {}, // 所有供应商的id-name键值对
      appraise: {
        // 评价
        supplierId: '',
        orderId: '', // 订单id
        delivery: 0,
        quality: 0,
        service: 0
      },
      qc: {
        dialogVisible: false,
        appendixPath: '', // 附件文件路径
        qcFilePath: '', // 交付件路径
        translatedFilePath: '', // 上传译稿展示路径
        tmFilePath: '', // 上传TM文件的展示路径
        remarks: '', // 说明
        qualityId: '' // 质量确认id
      },
      agcUid: '',
      agcTeamId: '',
      agcProjectId: '',
      zoneIdData: ''
    };
  },
  async mounted() {
    await getCPTeamIdData().then(res => {
      this.zoneIdData = res;
    });
    this.chStatus = this.searchStatus[0];
    this.chSupplier = this.searchSuppliers[0];
    this.initData();
    this.agcUid =
      Cookie.get('agc_uid') || sessionStorage.getItem('agc_uid');
    this.agcTeamId = AGC.getUserInfo().teamId;
    const productInfo = AGC.getProductInfo();
    if (productInfo) {
      this.agcProjectId = JSON.parse(AGC.getProductInfo()).productID;
    }
  },
  methods: {
    // 加载订单列表
    initData() {
      const $this = this;
      const body = {
        start: $this.pager.index * $this.pager.pageSize,
        length: $this.pager.pageSize
      };
      if ($this.chStatus.value !== 'all') {
        body.status = [$this.chStatus.value];
      }

      if ($this.searchValue && $this.searchValue.length > 0) {
        body.search = $this.searchValue;
      }
      if (
        $this.beginDate &&
        $this.endDate &&
        $this.beginDate !== $this.endDate
      ) {
        body.begin = $this.beginDate;
        body.end = $this.endDate;
      }
      body.spId = sessionStorage.getItem('agc_translation_spid');
      body.createTimeAscend = $this.createTimeAscend;
      body.costAscend = $this.costAscend;
      const header = { agcTeamId: $this.agcTeamId };
      const url = 'translation/v1/sp/taskList';
      AGC.callServiceAPI(
        url,
        { body, header },
        'POST',
        this.zoneIdData,
        3000,
        function(res) {
          if (res.code === 'success') {
            $this.data = res.data;
            $this.data.forEach(el => {
              el.sourceLanguage = el.sourceLanguage
                ? el.sourceLanguage.split('-')[0]
                : '';
              const tarLang = el.targetLanguage;
              const arr = [];
              tarLang.split(',').forEach(t => {
                arr.push(t.split('-')[0]);
              });
              el.targetLanguage = arr.join(this.$t('ctp.comma'));
            });
            $this.pager.total = res.total ? res.total : 10;
          } else {
            $this.data = [];
            $this.pager.total = 0;
            $this.showtoasts('danger', res['message-zh']);
          }
        },
        err => {
          $this.showtoasts('danger', err);
        }
      );
    },
    sortData(index) {
      switch (index) {
      case 1:
        this.createTimeAscend = !this.createTimeAscend;
        break;
      case 2:
        this.costAscend = !this.costAscend;
        break;
      default:
        this.createTimeAscend = !this.createTimeAscend;
      }
      this.initData();
    },
    showDetails(data) {
      const $this = this;
      AGC.callServiceAPI(
        'translation/v1/sp/taskDetails',
        {
          body: { taskId: data.taskId },
          header: { agcTeamId: $this.agcTeamId }
        },
        'POST',
        null,
        3000,
        res => {
          if (res.code === 'success') {
            $this.detailData = data;
            $this.detailData.taskReplyList = res.data.taskReplyList;
            $this.detailData.taskQualityList = res.data.taskQualityList;
            $this.detailData.targetLangList = res.data.targetLangList;
            $this.detailDialogVisiable = true;
          } else {
            $this.showtoasts('danger', res['message-zh']);
          }
        },
        err => {
          $this.showtoasts('danger', err);
        }
      );
    },
    getjwtToken() {
      const $this = this;
      AGC.callServiceAPI(
        'translation/v1/jwtToken',
        { header: { agcTeamId: $this.agcTeamId } },
        'POST',
        null,
        3000,
        res => res.data
      );
    },
    reset() {
      this.searchValue = '';
      const currTime = new Date().format('yyyy-MM-dd hh:mm');
      this.beginDate = currTime;
      this.endDate = currTime;
      this.chStatus = this.searchStatus[0];
    },
    cDownloadVisiable(dVisible) {
      this.downloadVisiable = dVisible;
    },
    // 选择译稿文件
    cosTranslated(event, path) {
      const $this = this;
      const suffix = event.target.value.split('.').pop();
      if (suffix !== 'zip') {
        $this.showtoasts('warning', 'please choose zip file.');
        this.$set($this.qc, path, '');
        event.target.value = '';
        return;
      }
      this.$set($this.qc, path, event.target.value);
    },
    // 弹出选择文件窗口
    chooseTM(fid) {
      document.getElementById(fid).click();
    },
    changeVisiable(dialogVisible) {
      this.detailDialogVisiable = dialogVisible;
    },
    openUploadDialog() {
      this.downloadVisiable = true;
    },
    closeDialog() {
      const $this = this;
      $this.qc.translatedFilePath = '';
      $this.qc.tmFilePath = '';
      $this.$refs.tsl_file.value = '';
      $this.$refs.tm_file.value = '';
      $this.uploadVisible = false;
    },
    // 评价
    openAppraiseDialog(orderid, supplierid) {
      this.appraise.supplierId = supplierid;
      this.appraise.orderId = orderid;
      this.appraiseDialogVisible = true;
    },
    // 提示窗
    showtoasts(type, message) {
      this.toasts = [{ type, summary: '提示', detail: message }];
    },
    // 分页
    onPage() {
      this.checkall = false;
      this.initData();
    }
  }
};
</script>

<style scoped>
.orderlist-bottom span {
  display: inline-block;
}
.orderlist-checkbox {
  display: inline-block;
  text-align: center;
  width: 18px;
  line-height: 16px;
  border-radius: 4px;
  cursor: pointer;
}
.checkbox-unchecked {
  border: 1px solid grey;
  background-color: #fff;
}
.checkbox-checked {
  border: 0;
  font-weight: 700;
  color: #169bd5;
  border: 1px solid #169bd5;
  padding-bottom: 2px;
}
.table {
  margin-bottom: 0;
}
.table tr.tr-main td {
  line-height: 32px;
  padding: 8px;
  background-color: #f5f6f7;
}
.table tr.tr-main:nth-child(2n + 1) {
  background-color: #f7f7f7;
}
.checkbox-checkAll-text {
  width: 40px;
  display: inline-block;
}
.orderlist-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.orderlist-process {
  color: #169bd5;
  cursor: pointer;
}
.order-detail-title {
  font-size: 16px;
  font-weight: 600;
}
.dialog-div-main {
  padding: 12px 0 36px 0;
  text-align: center;
  font-size: 20px;
}
.dialog-btn {
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  background-color: #169bd5;
}
.dialog-btn-middle {
  display: inline-block;
  width: 8px;
}
.orderlist-tr-nodata {
  text-align: center;
  color: #666;
  line-height: 48px;
}
.orderlist-detail {
  padding-bottom: 8px;
  border: 1px solid #169bd5;
}
.order-operate-btn-middle {
  padding: 2px 0;
}
.table > tbody > tr > td {
  border-top: 0;
}
.supplier-mar-right {
  margin-right: 4px;
  cursor: pointer;
  font-size: 18px;
}
.appraise-top ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.appraise-top {
  padding-left: 12px;
}
.supplier-lang-table {
  width: 100%;
}
.supplier-lang-table tr th,
td {
  padding: 4px 0 4px 12px;
}
.supplier-lang-table-bottom {
  border-top: 1px solid rgb(167, 164, 164);
}
.dialog-download-div {
  color: #169bd5;
  text-align: center;
}
.icon {
  cursor: pointer;
}
.qc-top {
  list-style: none;
  padding: 0;
}
.qc-top li {
  float: left;
  margin-right: 12px;
}
.order-nextstep {
  padding: 4px 24px;
  font-size: 14px;
  background-color: #169bd5;
  color: #fff;
  outline: none;
  border: 0;
  border-radius: 8px;
}
.order-empty {
  padding: 4px 24px;
  font-size: 14px;
  background-color: #fff;
  color: #999;
  outline: none;
  border: 1px solid #999;
  border-radius: 8px;
}
.order-detail-btn {
  padding: 4px 8px;
  font-size: 14px;
  background-color: #169bd5;
  color: #fff;
  outline: none;
  border: 0;
  border-radius: 4px;
}
.detail-dialog-div {
  padding: 4px 0;
}
.order-searchbtn {
  padding: 0 24px;
  font-size: 14px;
  background-color: #169bd5;
  color: #fff;
  outline: none;
  border: 1px solid #169bd5;
  line-height: 24px;
  border-radius: 4px;
}
.order-resetbtn {
  padding: 0 24px;
  font-size: 14px;
  background-color: #fff;
  color: #999;
  outline: none;
  line-height: 24px;
  border: 1px solid #999;
  border-radius: 4px;
}
.ave-datepicker {
  min-width: 140px;
}
.paying-dialog {
  color: #990000;
  font-size: 16px;
  text-align: center;
}
.paying-dialog-timer {
  color: #666;
  font-size: 14px;
  text-align: center;
}
.table > thead > tr > th {
  border-bottom: 0;
  border-top: 1px solid #e2e2e2;
}
.thead-tr > th:not(:first-child)::before {
  content: "";
  height: 8px;
  border-right: 1px solid #e2e2e2;
  margin-right: 8px;
}
.thead-tr th {
  border-top: 1px solid #e2e2e2;
}
.toasts-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 0;
  z-index: 9999;
  width: 30%;
  height: 100px;
}
.appraise-text {
  height: 72px;
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
}
.upload-btn {
  padding: 4px 12px;
  font-size: 14px;
  background-color: #fff;
  color: #3d3d3d;
  outline: none;
  border: 1px solid #999;
  border-radius: 8px;
}
.sort-caret {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  position: absolute;
  left: 7px;
}
.sort-caret.ascending {
  border-bottom-color: #c0c4cc;
  top: -13px;
}
.sort-caret.descending {
  border-top-color: #c0c4cc;
  bottom: -9px;
}
.ascending .sort-caret.ascending {
  border-bottom-color: #007dff;
}
.descending .sort-caret.descending {
  border-top-color: #007dff;
}
.caret-wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;
}
</style>
